<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Edit'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'list-stock-wholesale-clients' }">{{
            $t("STOCK_WHOLESALE_CLIENTS")
          }}</router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("EDIT") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center"></div>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col sm>
            <v-text-field
              :value="form.name"
              :label="$t('NAME')"
              outlined
              disabled
              readonly
            ></v-text-field>
          </b-col>
        </b-row>
        <v-tabs
          v-model="activeTab"
          v-if="!firstLoader"
          background-color="white"
          color="primary accent-4"
          centered
          icons-and-text
        >
          <v-tab>
            {{ $t("DESCRIPTION")
            }}<v-icon>mdi-note-text</v-icon></v-tab
          >
          <v-tab>
            {{ $t("PRICES_AND_PRODUCTS")
            }}<v-icon>mdi-pizza</v-icon></v-tab
          >

          <v-tab>
            {{ $t("STORES") }}

            <v-badge
              :value="_.size(form.stores) == 0"
              color="orange"
              content="!"
              overlap
            >
              <v-icon>mdi-home</v-icon>
            </v-badge>
          </v-tab>

          <v-tab-item :transition="false" :reverse-transition="false">
            <b-form>
              <FormBuilder
                v-if="!firstLoader"
                :form="form"
                :schemaJson="schemaJson"
                :v="$v"
              ></FormBuilder>

              <SkeletonLoaderMain :visible="firstLoader"></SkeletonLoaderMain>
              <v-overlay
                :value="
                  !firstLoader && (isLoadingStock || isLoadingSharedStore)
                "
              >
                <v-progress-circular
                  indeterminate
                  size="64"
                ></v-progress-circular>
              </v-overlay>
            </b-form>
          </v-tab-item>

          <v-tab-item :transition="false" :reverse-transition="false">
            <b-row>
              <b-col :lg="12">
                <v-simple-table
                  ><thead>
                    <tr>
                      <th width="10%">
                        <h4>
                          #
                        </h4>
                      </th>
                      <th width="10%">
                        <h4>
                          {{ $t("CODE") }}
                        </h4>
                      </th>
                      <th width="20%">
                        <h4>
                          {{ $t("NAME") }}
                        </h4>
                      </th>
                      <th width="20%">
                        <h4>
                          {{ $t("ITEM_WAREHOUSE") }}
                        </h4>
                      </th>
                      <th width="20%">
                        <h4>
                          {{ $t("UNIT") }}
                        </h4>
                      </th>
                      <th width="30%">
                        <h4>
                          {{ $t("PRICE") }}
                        </h4>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template>
                      <tr v-for="(item, i) in items"
                        :key="'item-'+i">
                        <td>
                          {{ item.id }}
                        </td>
                        <td>
                          {{ item.code }}
                        </td>
                        <td>
                            {{ item.name }}
                        </td>
                        <td>
                            {{ item.warehouseName }}
                        </td>
                        <td>
                            {{ item.unitName }}
                        </td>
                        <td>
                          
                          <template>
                            <v-text-field
                              v-model="item.price"
                              :label="$t('PRICE')"
                              hide-details
                              type="number"
                              step="0.01"
                              min="0"
                              @blur="filterSinglePrice(item)"
                            ></v-text-field>
                          </template>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </v-simple-table>
              </b-col>
            </b-row>
          </v-tab-item>
          <v-tab-item :transition="false" :reverse-transition="false">
            <b-row>
              <b-col :lg="12">
                <v-simple-table
                  ><thead>
                    <tr>
                      <th colspan="2">
                        <h3>
                          {{
                            $t(
                              "WHOLESALE_CLIENT_STORES"
                            )
                          }}
                        </h3>
                      </th>
                    </tr>
                    <tr>
                      <th width="10%">
                        <h4>
                          #
                        </h4>
                      </th>
                      <th width="25%">
                        <h4>
                          {{ $t("NAME") }}
                        </h4>
                      </th>
                      <th width="25%">
                        <h4>
                          {{ $t("ADDRESS") }}
                        </h4>
                      </th>
                      <th width="20%">
                        <h4>
                          {{ $t("NOTES") }}
                        </h4>
                      </th>
                      <th width="20%">
                        <h4>
                          {{ $t("OPTIONS") }}
                        </h4>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template>
                      <tr v-for="(store, i) in stockWholesaleClient.stores"
                        :key="i">
                        <td>
                          {{ i + 1 }}
                        </td>
                        <td>
                          <template v-if="selectedStoreId == store.id">
                            <v-text-field
                              v-model="store.name"
                              :label="$t('NAME')"
                              hide-details
                            ></v-text-field>
                          </template>
                          <template v-else>
                            {{ store.name }}
                          </template>
                        </td>
                        <td>
                          <template v-if="selectedStoreId == store.id">
                            <v-text-field
                              v-model="store.address"
                              :label="$t('ADDRESS')"
                              hide-details
                            ></v-text-field>
                          </template>
                          <template v-else>
                            {{ store.address }}
                          </template>
                        </td>
                        <td>
                          <template v-if="selectedStoreId == store.id">
                            <v-text-field
                              v-model="store.notes"
                              :label="$t('NOTES')"
                              hide-details
                            ></v-text-field>
                          </template>
                          <template v-else>
                            {{ store.notes }}
                          </template>
                        </td>
                        <td width="20%">
                          <template v-if="selectedStoreId != store.id">
                            <v-btn
                              class="mx-2"
                              dark
                              small
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                              @click="selectedStoreId = store.id"
                            >
                              {{ $t("EDIT") }}
                            </v-btn>
                          </template>
                          <template v-else>
                            <v-card-title>
                              <v-btn
                                class="mx-2"
                                dark
                                small
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                                @click="updateClientStore(store, false)"
                              >
                                {{ $t("APPLY") }}
                              </v-btn>
                              <v-tooltip color="red" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="mx-2"
                                    fab
                                    dark
                                    x-small
                                    color="red"
                                    @click="deleteClientStore(store, false)"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon dark>mdi-delete</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t("DELETE") }}</span>
                              </v-tooltip>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="mx-2"
                                    fab
                                    dark
                                    x-small
                                    @click="selectedStoreId = null"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon dark>mdi-close</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t("CLOSE") }}</span>
                              </v-tooltip>
                            </v-card-title>
                          </template>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          *
                        </td>
                        <td>
                          <template>
                            <v-text-field
                              v-model="newStore.name"
                              :label="$t('NAME')"
                              hide-details
                            ></v-text-field>
                          </template>
                        </td>
                        <td>
                          <template>
                            <v-text-field
                              v-model="newStore.address"
                              :label="$t('ADDRESS')"
                              hide-details
                            ></v-text-field>
                          </template>
                        </td>
                        <td>
                          <template>
                            <v-text-field
                              v-model="newStore.notes"
                              :label="$t('NOTES')"
                              hide-details
                            ></v-text-field>
                          </template>
                        </td>
                        <td width="20%">
                          <v-card-title>
                            <v-btn
                              class="mx-2"
                              dark
                              small
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                              @click="createClientStore()"
                            >
                              {{ $t("CREATE") }}
                            </v-btn>
                            </v-tooltip>
                          </v-card-title>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </v-simple-table>
              </b-col>
            </b-row>
          </v-tab-item>
        </v-tabs>
        <v-overlay
          :value="!firstLoader && (isLoadingStock || isLoadingSharedStore)"
        >
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <DeleteButton
            v-if="activeTab == 0  || activeTab == 1"
            v-on:onDelete="onDelete()"
            v-permission="['stock-wholesale-clients-delete']"
          ></DeleteButton>
          <SaveButtonDropdown
            v-if="activeTab == 0 || activeTab == 1"
            :defaultAction="'continue'"
            :isLoading="isLoadingStock"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  FETCH_CLIENT,
  UPDATE_CLIENT,
  DELETE_CLIENT,
  UPDATE_CLIENT_STORE,
  DELETE_CLIENT_STORE,
  SAVE_CLIENT_STORE
} from "@/modules/stock/store/stock.module";
import { FETCH_SELECTS_BASIC } from "@/core/services/store/shared_base.module";
import {
  FETCH_ALL_ITEMS_CLIENT_PRICE
} from "@/modules/item/store/item.module";

import FormBuilder from "@/view/content/forms/FormBuilder.vue";
import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";
import { validationMixin } from "vuelidate";

import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";

import KTCard from "@/view/content/Card.vue";
import { mapGetters, mapState } from "vuex";

export default {
  name: "ClientEdit",
  mixins: [validationMixin, formBuilderMixin],
  data() {
    return {
      activeTab: 0,
      errors: {},
      firstLoader: true,
      storesLoader: true,
      stockWholesaleClient: {},
      stores: [],
      isEdit: true,
      selectedStoreId: null,
      currencies: {},
      vatGroups: {},
      items: {},
      newStore: {
        name: "",
        address: "",
        notes: "",
        clientId: null
      }
    };
  },
  components: {
    KTCard,
    FormBuilder,
    SaveButtonDropdown
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Clients", route: { name: "list-stock-wholesale-clients" } },
      { title: "Edit Client" }
    ]);
    vm.getStockWholesaleClient();
    vm.getSelects();
    vm.getAllItems();
  },
  watch: {},
  computed: {
    ...mapGetters(["isLoadingStock", "isLoadingSharedStore", "currentUser"]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    schemaJson() {
      return {
        fields: [
          {
            type: "input",
            inputType: "number",
            id: "stock-wholesale-client-number-input",
            groupId: "stock-wholesale-client-number-group",
            required: "required",
            feedback_id: "stock-wholesale-client-live-feedback",
            divider: false,
            i18n: {
              label: "NUMBER",
              placeholder: "NUMBER",
              validation: "VALID_NUMBER"
            },
            validations: {
              required: true,
              minLength: 1
            },
            translatable: false,
            model: "number"
          },
          {
            type: "input",
            inputType: "text",
            id: "stock-wholesale-client-name-input",
            groupId: "stock-wholesale-client-name-group",
            required: "required",
            feedback_id: "stock-wholesale-client-live-feedback",
            divider: false,
            i18n: {
              label: "NAME",
              placeholder: "NAME",
              validation: "VALID_NAME"
            },
            validations: {
              required: true,
              minLength: 2
            },
            translatable: false,
            model: "name"
          },
          {
            type: "input",
            inputType: "text",
            id: "stock-wholesale-client-bulstat-input",
            groupId: "stock-wholesale-client-bulstat-group",
            required: "required",
            feedback_id: "stock-wholesale-client-live-feedback",
            divider: false,
            i18n: {
              label: "BULSTAT",
              placeholder: "BULSTAT",
              validation: "VALID_BULSTAT"
            },
            validations: {},
            translatable: false,
            model: "bulstat"
          },
          {
            type: "input",
            inputType: "text",
            id: "stock-wholesale-client-vat_number-input",
            groupId: "stock-wholesale-client-vat_number-group",
            required: "required",
            feedback_id: "stock-wholesale-client-live-feedback",
            divider: false,
            i18n: {
              label: "VAT_NUMBER",
              placeholder: "VAT_NUMBER",
              validation: "VALID_VAT_NUMBER"
            },
            validations: {},
            translatable: false,
            model: "vatNumber"
          },
          {
            type: "input",
            inputType: "text",
            id: "stock-wholesale-client-owner-input",
            groupId: "stock-wholesale-client-owner-group",
            required: "required",
            feedback_id: "stock-wholesale-client-live-feedback",
            divider: false,
            i18n: {
              label: "OWNER",
              placeholder: "OWNER",
              validation: "VALID_OWNER"
            },
            validations: {},
            translatable: false,
            model: "owner"
          },
          {
            type: "input",
            inputType: "text",
            id: "stock-wholesale-client-address-input",
            groupId: "stock-wholesale-client-address-group",
            required: "required",
            feedback_id: "stock-wholesale-client-live-feedback",
            divider: true,
            i18n: {
              label: "ADDRESS",
              placeholder: "ADDRESS",
              validation: "VALID_ADDRESS"
            },
            validations: {},
            translatable: false,
            model: "address"
          },
          {
            type: "select",
            inputType: "select",
            groupId: "stock-wholesale-client-group",
            id: "stock-wholesale-client-input",
            feedback_id: "stock-wholesale-client-live-feedback",
            divider: false,
            clearable: false,
            i18n: {
              label: "CURRENCY",
              placeholder: "CURRENCY"
            },
            options: this.currencies,
            validations: {
              required: true
            },
            model: "currencyId"
          },
          {
            type: "select",
            inputType: "select",
            id: "stock-wholesale-client-vat_rate-input",
            groupId: "stock-wholesale-client-vat_rate-group",
            required: "required",
            feedback_id: "stock-wholesale-client-live-feedback",
            divider: false,
            i18n: {
              label: "VAT_RATE",
              placeholder: "VAT_RATE"
            },
            options: this.vatGroups,
            validations: {},
            translatable: false,
            model: "vatGroupId"
          },
          {
            type: "switch",
            groupId: "item-isForeign-group",
            id: "item-isForeign-input",
            inputType: "checkbox",
            required: "required",
            feedback_id: "isForeign-live-feedback",
            colSm: "auto",
            divider: true,
            i18n: {
              label: "IS_FOREIGN",
              placeholder: "IS_FOREIGN"
            },
            validations: {
              required: true
            },
            model: "isForeign"
          }
        ]
      };
    }
  },
  validations() {
    let vm = this;
    let tmpValidationObject = this.generateFormValidationsOutOfSchemaJson(
      this.schemaJson
    );
    const passwordValidations = {
      required: true,
      minLength: 6
    };
    if ("password" in vm.form) {
      tmpValidationObject.form["password"] = vm.setItemValidations(
        passwordValidations
      );
      return tmpValidationObject;
    } else {
      return tmpValidationObject;
    }
  },
  methods: {
    getSelects() {
      let vm = this;
      let payload = {
        currencies: {
          fields: ["id", "name", "code"],
          search: ""
        },
        vat_groups: {
          fields: ["id", "name", "percent"],
          search: ""
        }
      };
      this.$store
        .dispatch(FETCH_SELECTS_BASIC, payload)
        .then(data => {
          vm.currencies = data.data.currencies;
          vm.vatGroups = data.data.vat_groups;
        })
        .catch(response => {
          console.log(response);
        });
    },
    getAllItems() {
      let vm = this;
      let params = {
        clientId: this.$route.params.id
      }
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_ALL_ITEMS_CLIENT_PRICE, apiParams)
        .then(data => {
          vm.items = data.data;
        })
        .catch(response => {
          console.log(response);
        });
    },
    getStockWholesaleClient() {
      let vm = this;
      let id = this.$route.params.id;
      this.$store
        .dispatch(FETCH_CLIENT, id)
        .then(data => {
          // vm.$notify({
          //   group: "notify",
          //   type: "success",
          //   title: "<i class='flaticon2-checkmark'></i> Success",
          //   text: data.message
          // });
          vm.$nextTick(function() {
            vm.stockWholesaleClient = data.data;
            this.generateFormOutOfSchemaJson(
              this.schemaJson,
              vm.stockWholesaleClient
            ).then(() => {
              vm.$set(vm.form, "stores", vm.stockWholesaleClient.stores);

              vm.prepareChangesCheck();
              vm.firstLoader = false;
            });
          });
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found"
            });
            this.$router.push({ name: "list-stock-wholesale-clients" });
          }
        });
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit(evt) {
      evt.preventDefault();
    },
    onSave(next) {
      let vm = this;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        vm.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Please fill all required fields correctly"
        });
        return;
      }
      const clientItems = vm.items
      .filter(item => item.price > 0) // filter out items with price <= 0
      .map(({ id, price }) => ({ itemId: id, itemPrice: price }));
          const payload = {
            client: this.form,
            items: clientItems
          };
      this.$store
        .dispatch(UPDATE_CLIENT, {
          id: vm.stockWholesaleClient.id,
          payload: payload
        })
        .then(data => {
          vm.errors = data.data.errors;
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });

          vm.prepareChangesCheck().then(() => {
            // let itemEdit = data.data;
            if (next == "continue") {
              //New
              // this.$router.push({
              //   name: "edit-stock-wholesale-client",
              //   params: { id: itemEdit.id }
              // });
            }
            if (next == "new") {
              //New
              this.$router.push({ name: "add-stock-wholesale-client" });
            }
            if (next == "exit") {
              this.$router.push({ name: "list-stock-wholesale-clients" });
            }
          });
        })
        .catch(function(response) {
          vm.errors = response.data.errors;
          vm.$notify({
            group: "notify",
            type: "error",
            title: "Error",
            text: response.data.message
          });
        });
    },
    onDelete() {
      let vm = this;
      let message = vm.$t("PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(function() {
          vm.$store
            .dispatch(DELETE_CLIENT, vm.stockWholesaleClient.id)
            .then(data => {
              vm.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message
              });

              vm.prepareChangesCheck().then(() => {
                vm.$router.push({ name: "list-stock-wholesale-clients" });
              });
            })
            .catch(function(response) {
              vm.$notify({
                group: "notify",
                type: "error",
                title: "Error",
                text: response.data.message
              });
            });
        })
        .catch(function() {});
    },
    deleteClientStore(store) {
      let vm = this;
      let message = vm.$t("PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(function() {
          vm.$store
            .dispatch(DELETE_CLIENT_STORE, store.id)
            .then(data => {
              vm.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message
              });
              vm.getStockWholesaleClient();
            })
            .catch(function(response) {
              vm.$notify({
                group: "notify",
                type: "error",
                title: "Error",
                text: response.data.message
              });
            });
        })
        .catch(function() {});
    },
    updateClientStore(store) {
      let vm = this;
      let payload = {
        name: store.name,
        address: store.address,
        notes: store.notes
      };
      this.$store
        .dispatch(UPDATE_CLIENT_STORE, {
          id: store.id,
          payload: payload
        })
        .then(data => {
          vm.errors = data.data.errors;
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          vm.selectedStoreId = null;
        })
        .catch(function(response) {
          vm.errors = response.data.errors;
          vm.$notify({
            group: "notify",
            type: "error",
            title: "Error",
            text: response.data.message
          });
        });
    },
    createClientStore() {
      let vm = this;
      this.newStore.clientId = vm.stockWholesaleClient.id;
      this.$store
        .dispatch(SAVE_CLIENT_STORE, this.newStore)
        .then(() => {
          vm.newStore = {
            name: "",
            address: "",
            notes: ""
          }
          vm.getStockWholesaleClient();
        })
        .catch(function(response) {
          vm.errors = response.data.errors;
          vm.$notify({
            group: "notify",
            type: "error",
            title: "Error",
            text: response.data.message
          });
        });
    },
    resetVForm() {
      this.$v.$reset();
    },
    filterSinglePrice(item) {
      item.price = this.$options.filters.formatBalance(
        item.price
      );
      this.updateItemTotalPrice(item);
    },
  }
};
</script>
